import {useAtom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'

import type {HelpText} from '@settleindex/domain'

const helpTextAtom = atomWithStorage<HelpText[]>('helpText', [], undefined, {getOnInit: true})

export const useHelp = () => {
  const [helpText, setHelpText] = useAtom(helpTextAtom)

  const helpFor = (id: string) =>
    helpText
      .find((help) => help.id.toLowerCase() === id.toLowerCase())
      ?.text?.map((t) =>
        // Replace quotes randomly inserted by Google
        t.replace('"', ''),
      )

  return {
    helpFor,
    helpText,
    setHelpText,
  }
}
