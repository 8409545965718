import {onError} from '@apollo/client/link/error'

import {message} from '@settleindex/react2'

import {disputeNotFoundGraphError, noReadAccessGraphError} from '../dispute/graph/disputeErrorGuards'
import {versionNotFoundGraphError} from '../version/graph/versionErrorGuards'

export const errorLink = onError((error) => {
  if (disputeNotFoundGraphError(error?.graphQLErrors?.[0])) {
    return
  }

  if (noReadAccessGraphError(error?.graphQLErrors?.[0])) {
    return
  }

  if (versionNotFoundGraphError(error?.graphQLErrors?.[0])) {
    return
  }

  message.info('Unsuccessful request', undefined, 'unsuccessful-request')
})
