import * as React from 'react'

import {useHelpQuery} from '../graphQLTypes'
import {useHelp} from './useHelp'

export const HelpProvider: React.FC = () => {
  const {data: helpData} = useHelpQuery()
  const {setHelpText} = useHelp()

  React.useEffect(() => {
    if (!helpData?.help) {
      return
    }

    setHelpText(helpData.help)
  }, [helpData, setHelpText])

  return <></>
}
