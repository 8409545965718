import styled from 'styled-components'

interface Props {
  paddingLeft: number
}

export const RightCol = styled.div<Props>`
  width: 100%;
  padding-left: ${(p: Props) => p.paddingLeft}px;
`
