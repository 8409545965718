import type * as React from 'react'

import {LoadingIndicatorFullPage} from '@settleindex/react2'

import {
  type CompleteMyRegistrationMutationVariables,
  useCompleteMyRegistrationMutation,
  useMeQuery,
} from '../graphQLTypes'
import {CompleteRegistrationForm} from './CompleteRegistrationForm'
import {useUser} from './useUser/useUser'

export const CompleteRegistrationContainer: React.FC = () => {
  const {user: oidcUser} = useUser()
  const {data, loading} = useMeQuery()
  const [completeRegistrationMutation, {loading: mutationLoading}] = useCompleteMyRegistrationMutation()

  if (loading || !data) {
    return <LoadingIndicatorFullPage />
  }

  const onSubmit = async (variables: CompleteMyRegistrationMutationVariables) => {
    await completeRegistrationMutation({variables})
  }

  /**
   * We merge the OIDC IdP user email into what our backend has about the user.
   * This is crucial for users who are NOT going through AWS Cognito:
   * without this we would never have access to their email and could not complete the registration.
   * A lack of name and email causes multiple issues around access rights, invitations etc.
   */
  const userData = {
    ...data.me.user,
    email: data.me.user.email.length ? data.me.user.email : (oidcUser?.email ?? ''),
  }

  return <CompleteRegistrationForm loading={mutationLoading} onSubmit={onSubmit} user={userData} />
}
