import {type StoreObject, defaultDataIdFromObject} from '@apollo/client'

const idForItemModifierResponse = (object: Readonly<StoreObject>) =>
  `${object.id}-${object.modifier}-ItemModifierResponse`

const idForPartyRoleResponse = (object: Readonly<StoreObject>) => `${object.id}-${object.role}-PartyRoleResponse`

const idForItemSumResponse = (object: Readonly<StoreObject>) => `${object.id}-${object.sum}-ItemSumResponse`

export const dataIdFromObject = (object: Readonly<StoreObject>) => {
  if (object.cacheId) {
    return `${object.__typename}-${object.cacheId}`
  }

  switch (object.__typename) {
    case 'ItemModifierResponse':
      return idForItemModifierResponse(object)
    case 'PartyRoleResponse':
      return idForPartyRoleResponse(object)
    case 'ItemSumResponse':
      return idForItemSumResponse(object)
    default:
      return defaultDataIdFromObject(object)
  }
}
