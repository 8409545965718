import React from 'react'

import {safeValue} from '@settleindex/fp'

import type {Stage} from '../Stage'

/**
 * TL;DR:
 * - add required env values to src/public/runtimeConfig.js.tpl
 * - sync these to https://github.com/SettleIndex/deploy/blob/main/workloads/app/dev/env for K8S
 *
 * Several config options are set using environment variables. As mentioned above,
 * the required variables are defined in `src/public/runtimeConfig.js.tpl`.
 *
 * In development the template is processed when running `pnpm dev`, see the `makeEnv` package.json script.
 *
 * Why is this required?
 *
 * In production (dev/QA/prod stages) we serve the app from a docker container.
 * The static js bundle and Docker image are generated only once and are reused in all stages.
 * So the various env values must be injected at runtime and this is why we generate the /runtimeConfig.js file.
 *
 * See the `docker/start.sh` on how this is done for Docker.
 */

interface Config {
  bugsnagKeyApp: string
  // The URL to pass to the FV instance
  featurevisorUrl?: string
  // The SHA of the git commit of the source. Set by Webpack at build time.
  gitSha: string
  // The URL of the GraphQL server, e.g. https://graph.settleindex.cloud/graphql
  graphUrl: string
  // The OIDC IdP authority to fetch `.well-known/openid-configuration` from, e.g.
  // https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_ABCdef123
  oidcAuthority: string
  // The OIDC IdP client ID
  oidcClientId: string
  // The hostname to use for the OIDC logout endpoint - in case of AWS Cognito
  // it is different from the oidcAuthority. Optional, defaults to oidcAuthority
  // e.g. https://auth.dev.settleindex.cloud
  oidcEndpoint: string
  // The scope for the OIDC authN (optional)
  // getStackEnvVars.sh returns an array like string: ["a", "b"] which we try to parse, but plain "a b c" also works.
  oidcScope: string
  // The AWS region, used by the auth config (login)
  region: string
  // The stage as in local, dev, QA or prod
  stage: Stage
  // Weather to enable the user admin features - they only work with AWS Cognito
  // and should only be enabled in the official SettleIndex instance (not client
  // instances)
  userAdminEnabled: boolean
  // The URL to the Web Service (successor to the Graph)
  wsUrl: string
}

type MaybeConfig = Record<string, string>

declare global {
  interface Window {
    runtimeConfig: MaybeConfig
  }
}

export const useConfig = (): Config => {
  const [config, setConfig] = React.useState<Config | undefined>(undefined)

  React.useEffect(() => {
    setConfig(getConfig())
  }, [])

  return config ?? getConfig()
}

/**
 * getConfigValue guarantees a value: it either finds one in the config objects, or
 * returns the default value (second argument).
 * If no value is found, it throws an error.
 *
 * Use UPPER_CASE names!
 */
export const getConfigValue = (key: string, defaultValue?: string): string =>
  safeValue((window.runtimeConfig?.[key] ?? defaultValue) as string, `useConfig/${key}`)

const getConfig = (): Config => ({
  gitSha: getConfigValue('GIT_SHA', 'no-git-sha'),
  graphUrl: getConfigValue('GRAPH_URL'),
  oidcAuthority: getConfigValue('OIDC_AUTHORITY'),
  oidcClientId: getConfigValue('OIDC_CLIENT_ID'),
  oidcEndpoint: getConfigValue('OIDC_ENDPOINT', getConfigValue('OIDC_AUTHORITY')),
  oidcScope: getConfigValue('OIDC_SCOPE', '')
    .replace('[', '')
    .replace(']', '')
    .replaceAll('"', '')
    .replaceAll(',', ' '),
  region: getConfigValue('REGION'),
  stage: getConfigValue('STAGE') as Stage,
  userAdminEnabled: getConfigValue('AWS_COGNITO_ENABLED') === 'true',
  featurevisorUrl: getConfigValue('FEATUREVISOR_URL'),
  wsUrl: getConfigValue('WS_URL'),
  bugsnagKeyApp: getConfigValue('BUGSNAG_KEY_APP', ''),
})
