import type * as React from 'react'

import {
  A,
  DebugIcon,
  EyeIcon,
  PowerIcon,
  PrimaryTextOnAccent,
  SettingsIcon,
  Tooltip,
  UserIcon,
  legacyDirectColors,
} from '@settleindex/react2'
import {primaryNavigationTestIds} from '@settleindex/testids'

import {paths} from '../../routing'
import type {PrimaryMenuItem} from './PrimaryMenuItem'

interface Args {
  debugLabelsEnabled: boolean
  isAdmin: boolean
  isDebugPanelVisible: boolean
  logout: () => void
  setDebugLabelsEnabled: (value: boolean) => void
  setIsDebugPanelVisible: (value: boolean) => void
  showDebugPanelMenuItem: boolean
  username: string | undefined
}

export const getBottomMenuItems = ({
  debugLabelsEnabled,
  isAdmin,
  isDebugPanelVisible,
  logout,
  setDebugLabelsEnabled,
  setIsDebugPanelVisible,
  showDebugPanelMenuItem,
  username,
}: Args): PrimaryMenuItem[] => {
  const logoutLinkProps = {
    onClick: (e: React.MouseEvent) => {
      e.preventDefault()
      logout()
    },
  }

  const adminLabelStyle = {color: legacyDirectColors.base6, cursor: 'pointer'}
  const adminLabelIconStyle = {...adminLabelStyle, display: 'block'}
  const debugLabelStyle = {
    color: debugLabelsEnabled ? legacyDirectColors.portage : legacyDirectColors.base6,
    cursor: 'pointer',
    fontSize: 13,
  }
  const debugLabelIconStyle = {...debugLabelStyle, display: 'block'}
  const debugLabelToggle = () => setDebugLabelsEnabled(!debugLabelsEnabled)

  const debugPanelStyle = {cursor: 'pointer', fontSize: 13, color: legacyDirectColors.base6}
  const debugPanelIconStyle = {...debugPanelStyle, display: 'block'}
  const debugPanelToggle = () => setIsDebugPanelVisible(!isDebugPanelVisible)

  return [
    isAdmin
      ? {
          icon: (
            <span style={adminLabelIconStyle}>
              <SettingsIcon size={16} />
            </span>
          ),
          key: primaryNavigationTestIds.admin,
          label: <PrimaryTextOnAccent style={{color: legacyDirectColors.base6}}>Admin</PrimaryTextOnAccent>,
          linkPath: paths.admin(),
        }
      : null,
    isAdmin
      ? {
          icon: (
            <span onClick={debugLabelToggle} style={debugLabelIconStyle}>
              <EyeIcon size={16} />
            </span>
          ),
          key: primaryNavigationTestIds.debugLabelsToggle,
          label: (
            <span onClick={debugLabelToggle} style={debugLabelStyle}>
              Labels
            </span>
          ),
        }
      : null,
    isAdmin && showDebugPanelMenuItem
      ? {
          icon: (
            <span onClick={debugPanelToggle} style={debugPanelIconStyle}>
              <DebugIcon size={16} />
            </span>
          ),
          key: primaryNavigationTestIds.debugPanelToggle,
          label: (
            <span onClick={debugPanelToggle} style={debugPanelStyle}>
              Debug
            </span>
          ),
        }
      : null,
    {
      key: primaryNavigationTestIds.account,
      icon: <UserIcon size={16} />,
      label: (
        <Tooltip placement="right" title={username}>
          <PrimaryTextOnAccent>Account</PrimaryTextOnAccent>
        </Tooltip>
      ),
    },
    {
      key: primaryNavigationTestIds.logout,
      icon: (
        <A variant="primaryOnAccent" {...logoutLinkProps} style={{cursor: 'pointer'}}>
          <PowerIcon size={16} />
        </A>
      ),
      label: (
        <A variant="primaryOnAccent" {...logoutLinkProps} style={{cursor: 'pointer'}}>
          Sign Out
        </A>
      ),
    },
  ]
}
