import {setContext} from '@apollo/client/link/context'

export const authLink = (getToken: () => string | void, getUserId: () => Promise<string | void>) =>
  setContext(async (_, {headers}) => {
    const token = getToken()
    const userId = await getUserId()

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-user-id': userId,
      },
    }
  })
