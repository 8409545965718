import * as React from 'react'

import {noop} from '@settleindex/fp'

import type {VersionPageTabKey} from '../version/page/tabs/VersionPageTabKey'

export interface AppContextValue {
  knowledgeBase: {
    isOpen: boolean
    setOpen: (value: boolean) => void
    setUrl: (url?: string) => void
    url?: string
  }
  loading: {
    isLoading: boolean
    setLoading: (l: boolean) => void
  }
  versionPage: {
    setTab: (key: VersionPageTabKey) => void
    tab: VersionPageTabKey
  }
}

export const defaultAppContextValue = {
  knowledgeBase: {
    isOpen: false,
    setOpen: noop,
    setUrl: noop,
    url: undefined,
  },
  loading: {
    isLoading: false,
    setLoading: noop,
  },
  versionPage: {
    setTab: noop,
    tab: 'claims' as VersionPageTabKey,
  },
}

export const AppContext = React.createContext<AppContextValue>(defaultAppContextValue)
