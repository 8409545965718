import * as React from 'react'

const suffix = ` - SettleIndex`
const defaultTitle = 'SettleIndex'
/**
 * Page titles are currently disabled due to privacy / security reasons.
 */
const setTitleEnabled = false

const updateTitle = (title?: string) => {
  if (!setTitleEnabled) {
    return
  }

  const htmlTitle = document.querySelector('title')
  if (!htmlTitle) {
    return
  }

  const titleToUse = title ? `${title}${suffix}` : defaultTitle

  htmlTitle.innerText = titleToUse
}

export const usePageTitle = (pageTitle?: string) => {
  React.useEffect(() => updateTitle(pageTitle), [pageTitle])

  return () => updateTitle(defaultTitle)
}
