import {minuteToMillisecond} from '@settleindex/domain'

/** The time in milliseconds after which the user will be logged out */
export const threshold = minuteToMillisecond(60)
/** The key to store the last action timestamp in localStorage */
export const stampKey = 'AutoLogout'
/** The throttle time for the activity check event listeners (eventsToListen) */
export const eventListenerThrottle = 5000
/** The events to listen for to verify timeout and update user activity */
export const eventsToListen = ['click', 'mouseMove', 'keydown']
/** The interval in milliseconds to check the user's activity */
export const checkInterval = minuteToMillisecond(0.25)
