import fsm from 'micro-fsm'
import {checkInterval, eventsToListen} from '#lib/autologout/autoLogoutConfig.js'
import {
  clearStamp,
  debug,
  debugAutoLogout,
  onUserEventThrottled,
  setStampToNow,
  stampExists,
  stampOlderThanThreshold,
} from '#lib/autologout/autoLogoutFns.js'

export const getMachine = ({
  onTimeout,
  // onUserEvent,
}: {
  /** The callback to run when the user times out */
  onTimeout: () => void
  /** The callback to run when the user interacts with the app */
  // onUserEvent: (event: unknown) => void
}) => {
  let intervalId: number

  const onUserEvent = onUserEventThrottled(onTimeout)

  const cleanup = () => {
    debug('cleanup: removing event listeners')
    for (const event of eventsToListen) {
      document.removeEventListener(event, onUserEvent)
    }

    debug('cleanup: clearing interval')
    window.clearInterval(intervalId)
  }

  /**
   * stopped -> check -> checking
   * stopped -> start -> started
   * stopped -> timeout -> timedOut
   *
   * checking -> start -> started
   * checking -> timeout -> timedOut
   *
   * started -> stop -> stopped
   * started -> timeout -> timedOut
   * started -> suspend -> suspended
   *
   * timedOut -> x
   */
  const machine = fsm('stopped', {
    /**
     * Check verifies once (on startup) that the user has not timed out since the last visit.
     * This state can be entered only once.
     **/
    check: {
      from: ['stopped'],
      to: 'checking',
      fn: () => {
        debug('checking')

        if (!stampExists()) {
          debug('checking: stamp does not exist')
        } else if (stampOlderThanThreshold()) {
          debug('checking: stamp is older than threshold, timing out')
          machine.timeout()
          return
        }
      },
    },
    /**
     * The machine does nothing in the stopped state, obviously :)
     */
    stop: {
      from: ['started', 'check'],
      to: 'stopped',
      fn: () => {
        cleanup()
      },
    },
    /**
     * In started the machine runs periodic checks and sets up event listeners for user interaction.
     */
    start: {
      from: ['checking', 'stopped'],
      to: 'started',
      fn: () => {
        debug('started')

        if (!stampExists()) {
          debug('start: stamp missing, setting it to now')
          setStampToNow()
        }

        // Check the timestamp periodically
        intervalId = window.setInterval(() => {
          debug('periodicCheck')
          debugAutoLogout()

          if (!stampExists()) {
            debug('periodicCheck: stamp does not exist, stopping')
            window.clearInterval(intervalId)
            machine.stop()
            return
          }

          if (stampOlderThanThreshold()) {
            debug('periodicCheck: stamp is older than threshold')
            window.clearInterval(intervalId)
            machine.timeout()
          }
        }, checkInterval)

        debug('start: adding event listeners')
        for (const event of eventsToListen) {
          document.addEventListener(event, onUserEvent)
        }
      },
    },
    /**
     * Timeout moves the machine into the "timed out" state,
     * which, on entering, will clear the timestamp and call the user specified callback.
     */
    timeout: {
      from: ['stopped', 'checking', 'started'],
      to: 'timedOut',
      fn: () => {
        debug('timeout reached')
        debug('timeout: clearing stamp')
        clearStamp()
        debug('timeout: running cleanup')
        cleanup()
        debug('timeout: calling onTimeout')
        onTimeout()

        machine.stop()
      },
    },
  })

  return machine
}
