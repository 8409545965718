import React, {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {LoadingIndicatorFullPage} from '@settleindex/react2'

import {paths} from './paths'

import {useUser} from '#lib/user/useUser/useUser.js'

const LoginPage = React.lazy(() => import('../user/LoginPage'))

export const PublicRouting: React.FC = () => {
  const {isLoading} = useUser()

  if (isLoading) {
    return <LoadingIndicatorFullPage />
  }

  return (
    <Suspense fallback={<LoadingIndicatorFullPage />}>
      <Switch>
        <Route path={paths.login}>
          <LoginPage />
        </Route>
        <Route>
          <Redirect to={paths.login} />
        </Route>
      </Switch>
    </Suspense>
  )
}
