import {atom, useRecoilState} from 'recoil'

const primaryNavExpandedState = atom<boolean>({
  key: 'primaryNavExpandedState',
  default: false,
})

const primaryNavLockedState = atom<boolean>({
  key: 'primaryNavLockedState',
  default: false,
})

export const usePrimaryNav = () => {
  const [isPrimaryNavExpanded, setIsPrimaryNavExpanded] = useRecoilState(primaryNavExpandedState)
  const [isPrimaryNavLocked, setIsPrimaryNavLocked] = useRecoilState(primaryNavLockedState)

  return {
    isPrimaryNavLocked,
    setIsPrimaryNavLocked,
    isPrimaryNavExpanded,
    setIsPrimaryNavExpanded,
  }
}
