import {intervalToHuman, timestampToHuman} from '@settleindex/domain'
import {throttle} from '@settleindex/fp'
import Debug from 'debug'
import {checkInterval, eventListenerThrottle, stampKey, threshold} from './autoLogoutConfig.js'

export const debug = Debug('app:autoLogout')

const getStamp = () => {
  const timeStr = window.localStorage.getItem(stampKey)

  return timeStr ? Number(timeStr) : null
}

export const stampExists = () => Boolean(getStamp())

export const setStampToNow = () => {
  debug('setStampToNow')
  window.localStorage.setItem(stampKey, String(Date.now()))
}

/**
 * The callback to run when the user interacts with the app
 * It has to do two things:
 * - verify if the user has been inactive for too long, and if so, log them out
 * - update the timestamp to the current time otherwise
 *
 * This needs to happen on every user interaction event as browsers will often throttle or completely pause setInterval
 * in background tabs. See https://github.com/whatwg/html/issues/6759#issuecomment-876777097
 * If we did not check both on each interaction, the user could leave the tab for a Very Long Time, then come back,
 * click the mouse and the timer would be reset (not logged out).
 */
const onUserEvent = (onTimeout: () => void) => () => {
  debug('onUserEvent')
  debugAutoLogout()
  if (stampOlderThanThreshold()) {
    debug('onUserEvent: stampOlderThanThreshold')
    onTimeout()
  } else {
    debug('onUserEvent: setStampToNow')
    setStampToNow()
  }
}
export const onUserEventThrottled = (onTimeout: () => void) => throttle(onUserEvent(onTimeout), eventListenerThrottle)
export const clearStamp = () => window.localStorage.removeItem(stampKey)
const stampAge = () => Date.now() - getStamp()
export const stampOlderThanThreshold = () => stampAge() > threshold

export const debugAutoLogout = () => {
  if (!stampExists()) {
    debug('DEBUG: stamp does not exist!')
    return
  }

  const info = {
    interval: intervalToHuman(checkInterval),
    threshold: intervalToHuman(threshold),
    stamp: timestampToHuman(getStamp()),
    now: timestampToHuman(Date.now()),
    elapsed: intervalToHuman(stampAge()),
    'Timeout?': stampAge() > threshold,
  }

  debug('DEBUG: auto-logout state ', JSON.stringify(info))
}
