import {useCallback} from 'react'
import {atom, useRecoilState} from 'recoil'

const debugLabelState = atom<boolean>({
  default: false,
  key: 'debugLabelState',
})

export const useDebugLabels = () => {
  const [debugLabelsEnabled, setDebugLabelsEnabled] = useRecoilState(debugLabelState)

  const toggleDebugLabels = useCallback(() => {
    setDebugLabelsEnabled((val) => !val)
  }, [setDebugLabelsEnabled])

  return {
    toggleDebugLabels,
    debugLabelsEnabled,
    setDebugLabelsEnabled,
  }
}
