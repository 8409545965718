import {type OidcConfiguration, OidcProvider} from '@axa-fr/react-oidc'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import {createRoot} from 'react-dom/client'
import {RecoilRoot} from 'recoil'

import {LoadingIndicatorFullPage} from '@settleindex/react2'

import {App} from './App'
import {captureException} from './captureException'
import {Ambulance} from './sections/Ambulance'
import {getConfigValue, useConfig} from './useConfig/useConfig'
import {LogoutPage} from './user/LogoutPage'

const bugsnagKey = getConfigValue('BUGSNAG_KEY_APP', '')

if (bugsnagKey) {
  Bugsnag.start({
    apiKey: bugsnagKey,
    appVersion: getConfigValue('GIT_SHA'),
    plugins: [new BugsnagPluginReact()],
    releaseStage: getConfigValue('STAGE'),
  })
  BugsnagPerformance.start({apiKey: getConfigValue('BUGSNAG_KEY_APP')})
}

const ErrorBoundaryPlaceholder: React.FC<React.PropsWithChildren> = ({children}) => <>{children}</>

const ErrorBoundary = bugsnagKey ? Bugsnag.getPlugin('react')!.createErrorBoundary(React) : ErrorBoundaryPlaceholder

const Index = () => {
  const config = useConfig()

  const oidcConfiguration = React.useMemo<OidcConfiguration>(() => {
    const isAwsCognito = config.oidcAuthority.includes('cognito-idp')
    const authority_configuration = isAwsCognito
      ? {
          // This is Cognito specific and required for logout to work with user pool users.
          // See https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-userpools-server-contract-reference.html
          authorization_endpoint: `${config.oidcEndpoint}/oauth2/authorize`,
          end_session_endpoint: `${config.oidcEndpoint}/logout`,
          issuer: config.oidcAuthority,
          revocation_endpoint: `${config.oidcEndpoint}/oauth2/revoke`,
          token_endpoint: `${config.oidcEndpoint}/oauth2/token`,
          userinfo_endpoint: `${config.oidcEndpoint}/oauth2/userInfo`,
        }
      : undefined

    return {
      client_id: config.oidcClientId,
      redirect_uri: `${location.origin}/authentication/callback`,
      silent_redirect_uri: `${location.origin}/authentication/silent-callback`,
      scope: config.oidcScope,
      authority: config.oidcAuthority,
      service_worker_only: false,
      authority_configuration,
      // The default storage is sessionStorage, but that would make e2e testing practically impossible.
      storage: localStorage,
    }
  }, [config.oidcAuthority, config.oidcClientId, config.oidcEndpoint, config.oidcScope])

  const onOidcEvent = React.useCallback((_configurationName: string, eventName: string, _data: any) => {
    // See https://github.com/AxaFrance/react-oidc/blob/e3d208f86eba26765e064db675fed2cc4f15c846/packages/react/src/oidc/vanilla/events.ts
    if (eventName.endsWith('_error')) {
      captureException(new Error(`OIDC event ${eventName} with data ${JSON.stringify(_data)}`))
    }
  }, [])

  const AmbulanceHome = React.useCallback(() => <Ambulance to="/" />, [])

  React.useEffect(() => {
    if (document.location.href.includes('localhost')) {
      document.title = '(local) SettleIndex'
    }
  }, [])

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <RecoilRoot>
          <OidcProvider
            authenticatingComponent={LoadingIndicatorFullPage}
            authenticatingErrorComponent={AmbulanceHome}
            callbackSuccessComponent={LoadingIndicatorFullPage}
            configuration={oidcConfiguration}
            loadingComponent={LoadingIndicatorFullPage}
            onEvent={onOidcEvent}
            sessionLostComponent={LogoutPage}
          >
            <App featurevisorUrl={config.featurevisorUrl} graphUrl={config.graphUrl} useBugsnag={!!bugsnagKey} />
          </OidcProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </React.StrictMode>
  )
}

const container = document.querySelector('#root')
const root = createRoot(container!)
root.render(<Index />)
