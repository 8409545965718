import * as React from 'react'

import {captureException} from '../captureException'
import {AppContext} from './AppContext'

export const useAppIsLoading = () => {
  const {
    loading: {isLoading: loading, setLoading},
  } = React.useContext(AppContext)

  const runAsync = async (fn: () => Promise<any>) => {
    setLoading(true)

    try {
      return await fn()
    } catch (error) {
      captureException(error)

      throw error
    } finally {
      setLoading(false)
    }
  }

  return {loading, setLoading, runAsync}
}
