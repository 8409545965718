import {useState} from 'react'
import {clearStamp} from './autoLogoutFns.js'
import {getMachine} from './getMachine.js'

/**
 * Auto-logout hook
 *
 * Returns the following functions:
 *
 * - check: this should run before anything else to ensure the user is force logged out if they have been away for too long
 * - start: starts the periodic check to detect inactivity and returns a callback to clear the timers and event listeners
 * - clear: clears the stamp
 *
 * To configure the auto-logout logic, see ./autoLogoutFns.ts
 */
export const useAutoLogout = ({onTimeout}: {onTimeout: () => void}) => {
  const [machine] = useState(getMachine({onTimeout}))
  const check = () => {
    if (machine.can('check')) {
      machine.check()
    }
  }

  /** Starts the machine and returns a function to stop it, use it to clear up the useEffect */
  const start = () => {
    if (machine.can('check')) {
      machine.check()
    }

    if (machine.can('start')) {
      machine.start()

      return () => {
        machine.stop()
      }
    }
  }

  return {check, start, clear: clearStamp}
}
