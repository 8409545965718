import {atom} from 'recoil'

import type {Nullable} from '@settleindex/domain'

import type {DisputeFragment} from '../../graphQLTypes'

export const disputeAtom = atom<Nullable<DisputeFragment>>({
  key: 'disputeAtom',
  default: undefined,
})
