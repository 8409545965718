export const typePolicies = {
  DisputeResponse: {
    fields: {
      versions: {
        merge: false,
      },
    },
  },
  VersionResponse: {
    fields: {
      costTotals: {merge: false},
      cumulativeWeights: {merge: false},
      decisions: {merge: false},
      opponentValues: {merge: false},
      outcomes: {merge: false},
      partyRoles: {merge: false},
    },
  },
  DecisionResponse: {
    fields: {
      partyValues: {merge: false},
    },
  },
  OutcomeResponse: {
    fields: {
      costPartyTotals: {merge: false},
      costRecoveryPartyAmounts: {merge: false},
      partyAbsoluteValues: {merge: false},
      partyValues: {merge: false},
    },
  },
  ClaimGroupResponse: {
    fields: {
      claims: {merge: false},
    },
  },
}
