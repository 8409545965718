import {Checkbox, FormItem, PrimaryButton, TextInput} from '@settleindex/react2'

import {registerTestIds} from '@settleindex/testids'
import {emailRegex} from './emailRegex'
import {intlPhoneRegex} from './intlPhoneRegex'

export const fieldNames = {
  id: 'id',
  company: 'company',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  notify: 'notify',
  phone: 'phone',
}

const defaultLabels = {
  [fieldNames.company]: 'Company',
  [fieldNames.email]: 'Email Address',
  [fieldNames.firstName]: 'First Name',
  [fieldNames.lastName]: 'Last Name',
  [fieldNames.company]: 'Company',
  [fieldNames.phone]: 'Phone',
}

interface Args {
  customLabels?: {[key: string]: string}
  formItemProps?: {[key: string]: any}
  onNotifyChange?: (event: any) => void
  required?: {[key: string]: boolean}
  submitLabel: string
}

export const userEditFields = ({customLabels, formItemProps, onNotifyChange, required, submitLabel}: Args) => {
  const labels = {
    ...defaultLabels,
    ...customLabels,
  }

  return {
    id: (
      <FormItem name={fieldNames.id} noStyle>
        <input type="hidden" />
      </FormItem>
    ),
    firstName: (
      <FormItem label={labels[fieldNames.firstName]} name={fieldNames.firstName}>
        <TextInput />
      </FormItem>
    ),
    lastName: (
      <FormItem label={labels[fieldNames.lastName]} name={fieldNames.lastName}>
        <TextInput />
      </FormItem>
    ),
    email: (
      <FormItem label={labels[fieldNames.email]} name={fieldNames.email}>
        <TextInput pattern={emailRegex} />
      </FormItem>
    ),
    company: (
      <FormItem label={labels[fieldNames.company]} name={fieldNames.company}>
        <TextInput />
      </FormItem>
    ),
    phone: (
      <FormItem
        label={labels[fieldNames.phone]}
        name={fieldNames.phone}
        pattern={{value: intlPhoneRegex, message: 'A phone number with country code is required'}}
        required={required?.[fieldNames.phone] ?? true}
        {...formItemProps?.[fieldNames.phone]}
      >
        <TextInput placeholder="+44 1234 567" data-test-id={registerTestIds.phoneField} />
      </FormItem>
    ),
    submit: <PrimaryButton htmlType="submit">{submitLabel}</PrimaryButton>,
    notify: onNotifyChange ? (
      <FormItem name={fieldNames.notify}>
        <Checkbox defaultChecked={false} onChange={onNotifyChange}>
          Notify via Email
        </Checkbox>
      </FormItem>
    ) : null,
  }
}
