import type * as React from 'react'
import styled from 'styled-components'

import {isDefined} from '@settleindex/fp'
import {GridRow as BaseRow, PrimaryTextOnAccent, hex2rgba, legacyDirectColors} from '@settleindex/react2'

import {collapsedPrimaryNavWidth, expandedPrimaryNavWidth} from '../../page/consts'
import type {PrimaryMenuItem} from './PrimaryMenuItem'

import {Link} from '#lib/link/Link.js'

const menuRowHeight = 28
const menuRowBottomMargin = 5
const menuPillWidth = 175
const menuIconWidth = 28
const menuLabelWidth = menuPillWidth - menuIconWidth

const IconCol = styled.div`
  width: ${menuIconWidth}px;
  min-width: 28px;
  height: ${menuRowHeight}px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${legacyDirectColors.base8};
`

export const LabelCol = styled.div``

interface MenuRowProps {
  $active?: boolean
  $expanded?: boolean
  $hasHoverEffect?: boolean
}

const MenuRow = styled(BaseRow)<MenuRowProps>`
  min-height: ${menuRowHeight}px;
  width: ${(props: MenuRowProps) => (props.$expanded ? expandedPrimaryNavWidth : collapsedPrimaryNavWidth)}px;
  width: calc(100% - 32px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 auto;
  margin-bottom: ${menuRowBottomMargin}px;
  white-space: nowrap;
  border-radius: 3px;

  background-color: ${(props: MenuRowProps) => (props.$active ? '#25233E' : undefined)};

  &:hover {
    background-color: ${(props: MenuRowProps) => {
      if (props.$hasHoverEffect === false) {
        return undefined
      }

      return props.$active ? hex2rgba('#25233E', 1) : hex2rgba('#25233E', 0.5)
    }};
  }
`

const GapRow = styled.div`
  height: ${menuRowHeight}px;
`

interface Props {
  expanded: boolean
  menuItems: PrimaryMenuItem[]
}

export const MenuSection: React.FC<Props> = ({expanded, menuItems}) => (
  <>
    {menuItems.filter(isDefined).map((item, index) => {
      if (item.isGap === true) {
        return <GapRow key={item.key ?? index} />
      }

      const labelStyle = {
        marginLeft: item.icon ? 15 : 9,
        width: item.icon ? menuLabelWidth : menuPillWidth,
      }

      const visibleMenuItem = (
        <MenuRow
          $active={item.isActive}
          $expanded={expanded}
          $hasHoverEffect={item.hasHoverEffect}
          data-test-id={item.key}
          key={item.key}
        >
          {item.icon && <IconCol>{item.icon}</IconCol>}
          <LabelCol>
            {typeof item.label === 'string' ? (
              <PrimaryTextOnAccent style={labelStyle}>{item.label}</PrimaryTextOnAccent>
            ) : (
              <span style={labelStyle}>{item.label}</span>
            )}
          </LabelCol>
        </MenuRow>
      )

      return item.linkPath ? (
        <Link key={item.key} to={item.linkPath} top>
          {visibleMenuItem}
        </Link>
      ) : (
        visibleMenuItem
      )
    })}
  </>
)
